import React, { useState, useEffect } from "react";
import { AppContext } from "context";
import { isEmpty } from "lodash";
import axios from "axios";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { FILTER_TYPE } from "../constants";
import { format, sub } from "date-fns";
import moment from "moment";


const createdPlatform = "GRS-Web"
let startOfMonth = new Date(moment().clone().startOf('month').format('YYYY-MM-DD hh:mm'));
const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD hh:mm');

const ContextController = (props) => {
  //Form and Dropdown States
  const [status, setStatus] = useState("");
  const [complaintStatus, setComplaintStatus] = useState([]);

  //Table data for pages
  const [greviencesData, setGrievenceData] = useState([])
  const [greviencesDataAdmin, setGrievenceDataAdmin] = useState([])
  const [officerTableData, setOfficerTableData] = useState([])

  //Pagination Data
  const [totalAdminData, setTotalAdminData] = useState([])
  const [totalData, setTotalData] = useState([])

  //Filter Data
  const [grieviencesDropdownData, setGrieviencesDropdownData] = useState([])
  const [grievienceValue, setGrievienceValue] = useState({})


  //Url params and form data
  const [contactNumber, setMobileNumber] = useState("")
  const [otherComment, setotherComment] = useState("")
  const [grievience, setGrievience] = useState({})
  const [ddn, setddn] = useState("")
  const [evidanceImg, setEvidanceImg] = useState({})

  const [dummycontactNumber, setDummycontactNumber] = useState("")
  const [name, setName] = useState("")
  const [pageNumber, setpageNumber] = useState(1)
  const [totalOfficerData, setTotalOfficerData] = useState("")
  const [wardNumber, setWardNumber] = useState("")



  //Filter States
  const [typeFilter, settypeFilter] = useState("")
  const [statusFilter, setstatusFilter] = useState("")
  const [dateValue, setDateOnChange] = useState([new Date(startOfMonth), new Date(endOfMonth)]);
  const [numberOfRecords, setnumberOfRecords] = useState(10);
  const [adminStats, setAdminStats] = useState({});

  // Search Texts

  const [searchString, setSearchString] = useState("");


  const history = useHistory()
  const search = useLocation().search;


  useEffect(() => {
    getGrieviencesDropdownData()
    getGrieviencesStatus()
    getUrlParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, typeFilter, statusFilter, dateValue,  numberOfRecords])

  useEffect(() => {
    userData()
    adminData()
    officerData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, typeFilter, statusFilter, dateValue, grievience, numberOfRecords])

  useEffect(() => {
    getAdminStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue])

  useEffect(() =>{
    // console.log('evidanceImg',  evidanceImg)
  },[evidanceImg])


  /** Handle Name Change
  */
  const updateName = (name) => {
    setName(name)
  }

  /** Handle Date changes
  */
  const dateOnChange = (date) => {
    setDateOnChange(date)
  }

  /** Get and Set Url Params
  */
  const getUrlParams = () => {
    const name = new URLSearchParams(search).get("name");
    const ddn = new URLSearchParams(search).get("ddn");
    const contactNumber = new URLSearchParams(search).get("contactNumber");
    const error = new URLSearchParams(search).get("error");

    if (name && ddn && contactNumber) {
      localStorage.setItem("items", JSON.stringify({ name, ddn, contactNumber }))
      updateName(name)
      setddn(ddn)
      setDummycontactNumber(contactNumber)
    } else if (error) {
      alert(`Error!, ${error}`)
      history.push({
        pathname: "/error",
        state: { error }
      })
    }
    return search

  }

  /** Add Grirevence
  */
  const addGrieviences = async (body) => {
    try {
      const formData = new FormData();
      formData.append('evidanceImage', evidanceImg);
      let image = await axios.post(`${process.env.REACT_APP_ENDPOINT}/grievance-redressal/upload`, formData)
      // console.log("image", image.data.data.url)
      const body = {
        ddn,
        "type": grievience,
        createdPlatform,
        contactNumber: contactNumber.toString(),
        otherComment,
        evidanceImage: image?.data?.data?.url
      };
      // console.log("body:-------- ", body);
      let res = await axios.post(`${process.env.REACT_APP_ENDPOINT}/grievance-redressal`, body)
      alert(res.data.message)
      setMobileNumber("")

    } catch (err) {
      console.log("err===>", err)
      alert(`Error! ${err.response.data.error}`)
    }
  }
  /** Common Grieviences Data Hook
   * @params params
  */
  const getGrieviencesData = async ({ params }) => {
    try {
      
      // console.log("params", params)
      let endpoint = "/grievance-redressal"
      let res = await axios.get(`${process.env.REACT_APP_ENDPOINT}${endpoint}`, { params })
      const { grievances, totalCount } = res.data.data
      return { success: true, data: { grievances, totalCount } }
    } catch (error) {
      console.log("Error!", JSON.stringify(error, null, 4))
      throw error
    }
  }

  const getAdminStats = async () => {
    try {
      let endpoint = "/grievance-redressal/stats?"
      let params = {};
      if (!isEmpty(dateValue)) {
        params.from = format(sub(new Date(dateValue[0]), {
          hours: 5,
          minutes: 30
        }), "yyyy-MM-dd")
        params.to = format(sub(new Date(dateValue[1]), {
          hours: 5,
          minutes: 30
        }), "yyyy-MM-dd")
      }
      // console.log("params", params);
      let res = await axios.get(`${process.env.REACT_APP_ENDPOINT}${endpoint}`, { params })
      const { data } = res.data

      setAdminStats(data)
    } catch (error) {
      alert("Error!")
      throw error
    }
  }


  /** Get User table data
  */
  const getGrieviencesTableData = async () => {
    try {
      const items = JSON.parse(localStorage.getItem("items"));
      let params = {}

      !isEmpty(items) && !isEmpty(items.ddn) && (params.ddn = items.ddn)
      typeFilter && (params.type = typeFilter)
      statusFilter && (params.status = statusFilter)
      if (!isEmpty(dateValue)) {
        params.from = format(new Date(dateValue[0]), "yyyy-MM-dd")
        params.to = format(new Date(dateValue[1]), "yyyy-MM-dd")
      }

      if (!isEmpty(items) && !isEmpty(items.ddn)) {
        const res = await getGrieviencesData({ params })
        const { totalCount, grievances } = res.data
        setGrievenceData(grievances)
        setTotalData(totalCount)

      }
    } catch (error) {
      console.log("error====>", error)
      return false
    }
  }

  /**  Get Admin table data
  */
  const getGrieviencesTableDataAdmin = async () => {
    try {

      let params = {}
      typeFilter && (params.type = typeFilter)
      statusFilter && (params.status = statusFilter)
      numberOfRecords && (params.limit = numberOfRecords)
      pageNumber && (params.page = pageNumber)

      if(searchString !== "" ){
        delete params.limit
      }
       if (searchString === "") {
        params.limit = numberOfRecords
      }

      if (!isEmpty(dateValue)) {
        params.from = format(dateValue[0], "yyyy-MM-dd")
        params.to = format(dateValue[1], "yyyy-MM-dd")
      }
      let res = await getGrieviencesData({ params })
      const { grievances, totalCount } = res.data
      setGrievenceDataAdmin(grievances)
      setTotalAdminData(totalCount)

      return true
    } catch (error) {
      console.log("error====>", error)
      alert("Error!")
      return false
    }
  }

  /**  Get Officer table data
    * @params wardNumber
  */
  const getOfficerTableData = async (wardNumber) => {
    try {
      let params = {}


      if (wardNumber) {
        params.wardNumber = wardNumber
        typeFilter && (params.type = typeFilter)
        statusFilter && (params.status = statusFilter)
        if (!isEmpty(dateValue)) {
          params.from = format(dateValue[0], "yyyy-MM-dd")
          params.to = format(dateValue[1], "yyyy-MM-dd")
        }

        numberOfRecords && (params.limit = numberOfRecords)
        pageNumber && (params.page = pageNumber)

        let res = await getGrieviencesData({ params })
        let { totalCount, grievances } = res.data
        setOfficerTableData(grievances)
        setTotalOfficerData(totalCount)

      }
    } catch (error) {
      console.log("error====>", error)
      alert("Error!")
    }
  }


  /** Officer Data Call
  */
  const officerData = async () => {
    const wardNumber = new URLSearchParams(search).get("wardNumber");
    setWardNumber(wardNumber)
    await getOfficerTableData(wardNumber)
  }

  /** Admin Data Call
  */
  const adminData = async () => {
    await getGrieviencesTableDataAdmin()
  }
  /** User Data Call 
  */
  const userData = async () => {
    await getGrieviencesTableData()
  }

  /** Update status or Type
   * @params _id
   * @params status
   * @params type
  */
  const updateStatusOrType = async (_id, status, type) => {
    try {
      let body = {}
      !isEmpty(status) && (body.status = status)
      !isEmpty(type) && (body.type = type)
      let res = await axios.put(`${process.env.REACT_APP_ENDPOINT}/grievance-redressal/${_id}`, body)
      getGrieviencesTableData()
      getGrieviencesTableDataAdmin()
      return res

    } catch (error) {
      console.log("error====>", JSON.stringify(error, null, 4))
      alert("This grievance is already marked RESOLVED OR FAILED TO RESOLVE. Please create a new grievance")

    }
  }

  /** Update Type
    * @params _id
    * @params type
  */
  const updateType = async (_id, type) => {
    try {
      await updateStatusOrType(_id, "", type)
    } catch (error) {
      console.log("error====>", JSON.stringify(error, null, 4))
      alert("Error!")

    }
  }

  /** Update status 
    * @params _id
    * @params status
    * @params previousStatus
  */
  const updateStatus = async (_id, status, previousStatus) => {
    try {
      if (!(previousStatus === "RESOLVED"
        || previousStatus === "FAILED_TO_RESOLVE")) {
        await updateStatusOrType(_id, status)
      } else {
        alert("This grievance is already marked RESOLVED OR FAILED TO RESOLVE. Please create a new grievance")

      }

    } catch (error) {
      alert("Error!")

    }
  }

  /** Set Grievience dropdown change
  */
  const getGrieviencesStatus = async () => {
    try {
      let res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/grievance-redressal/statuses`)
      let { data } = res.data
      setComplaintStatus(data.statuses)
    } catch (error) {
      alert("Error!")
    }
  }

  /** Handle Grievience dropdown data
  */
  const getGrieviencesDropdownData = async () => {
    console.log("call me")
    try {
      let res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/grievance-redressal/types`)
      setGrieviencesDropdownData(res.data.data.types)
      setGrievienceValue({ displayName: res.data.data.types[0].displayName, value: res.data.data.types[0].value })
      setGrievience(res.data.data.types[0].value)

    } catch (error) {
      alert("Error!")
    }
  }


  /** Handle Type or status filter change
    * @params value
    * @params type
  */
  const changeTypeOrStatusFilter = async (value, type) => {
    setpageNumber(1)
    if (type === FILTER_TYPE.STATUS) setstatusFilter(value)
    else settypeFilter(value)
    // getGrieviencesTableData()

  }

  const updateSearchText = async (value) => {
    setSearchString(value)
  }
  useEffect(()=>{
    setpageNumber(1)
  }, [typeFilter, statusFilter, dateValue, grievience, numberOfRecords])

  // useEffect(()=>{
  //   console.log(grievienceValue);
  //   console.log(grievience)
  // },[grievienceValue, grievience])
  return (
    <AppContext.Provider
      value={{
        typeFilter,
        settypeFilter,
        statusFilter,
        changeTypeOrStatusFilter,
        setstatusFilter,
        addGrieviences,
        greviencesData,
        getGrieviencesTableData,
        getGrieviencesDropdownData,
        grieviencesDropdownData,
        grievienceValue,
        setGrievienceValue,
        setGrievience,
        setMobileNumber,
        setotherComment,
        otherComment,
        updateStatus,
        complaintStatus,
        setComplaintStatus,
        setStatus,
        status,
        name,
        updateName,
        contactNumber,
        ddn, setddn,
        dummycontactNumber, setDummycontactNumber,
        getGrieviencesTableDataAdmin,
        greviencesDataAdmin, setGrievenceDataAdmin,
        getUrlParams,
        updateType,
        totalAdminData,
        setTotalAdminData,
        totalData,
        setTotalData,
        dateValue,
        dateOnChange,
        pageNumber,
        setpageNumber,
        getOfficerTableData,
        officerTableData,
        totalOfficerData,
        setTotalOfficerData,
        numberOfRecords,
        setnumberOfRecords,
        wardNumber,
        setWardNumber,
        getAdminStats,
        adminStats,
        setAdminStats,
        updateSearchText,
        searchString,
        evidanceImg,
        setEvidanceImg

      }}>
      {props.children}
    </AppContext.Provider >
  );
};

export default withRouter(ContextController)