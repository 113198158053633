import { Center, Text, Wrap, WrapItem } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { AppContext } from '../../../../context'
import React, { useContext, useEffect } from 'react'

export const StaticsticsCard = () => {
    const context = useContext(AppContext)
    useEffect(() => {
        context.getAdminStats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppContext.Consumer>
            {context => (<>
                <Wrap spacing='30px' justify='center' mt='50px'>
                    <WrapItem>
                        <Card bg='#23418D'>
                            <Center w='250px' h='200px' bg='#23418D' rounded="xl" >
                                <Text color="white" p="4" fontSize="xl" fontWeight="semibold" align="center" >
                                    Total Properties<br />
                                    Onboarded<br /><br /><br />
                                    {context.adminStats.totalPropertiesOnboarded}
                                </Text>
                            </Center>
                        </Card>

                    </WrapItem>
                    <WrapItem>
                        <Card bg='#23418D'>
                            <Center w='250px' h='200px' bg='#23418D' rounded="xl">
                                <Text color="white" p="4" fontSize="xl" fontWeight="semibold" align="center" >
                                    Total Grievances<br />
                                    (Last 30 Days)<br /><br /><br />
                                    {context.adminStats.totalGrievances}
                                </Text>
                            </Center>
                        </Card>
                    </WrapItem>
                    <WrapItem>
                        <Card bg='#23418D'>
                            <Center w='250px' h='200px' bg='#23418D' rounded="xl">
                                <Text color="white" p="4" fontSize="xl" fontWeight="semibold" align="center" >
                                    Total Resolved Grievances<br />
                                    (Last 30 Days)<br /><br />
                                    {context.adminStats.totalGrievancesResolved}
                                </Text>
                            </Center>
                        </Card>
                    </WrapItem>
                    <WrapItem>
                        <Card bg='#23418D'>
                            <Center minW='250px' h='200px' bg='#23418D' rounded="xl">
                                <Text color="white" p="4" fontSize="xl" fontWeight="semibold" align="center" >
                                    Total Unresolved <br /> Grievances<br />(Last 30 Days)<br /><br />
                                    {context.adminStats.totalGrievancesUnresolved}
                                </Text>
                            </Center>
                        </Card>
                    </WrapItem>
                </Wrap>

                <Wrap justify='center' my="20px" mx='10px' mb="100px">
                    <WrapItem>
                        <Card bg='#23418D'>
                            <Center w='530px' h='200px' bg='#23418D' rounded="xl">
                                <Text color="white" p="4" fontSize="xl" fontWeight="semibold" align="center" >
                                    Average Time Taken To Resolve <br /> Grievances
                                    <br />(Last 30 Days)<br /><br />
                                    {context.adminStats.averageTimetakenToResolve}
                                </Text>
                            </Center>
                        </Card>
                    </WrapItem>
                </Wrap>
            </>)
            }

        </AppContext.Consumer >
    )
}
